<template>
  <b-card
    v-if="data"
    no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>حالة المستفيدين في كل خدمة</b-card-title>
      <b-col
        cols="12"
        md="3"
        class="mb-md-0 mb-2"
      >

        <label> الخدمة</label>
        <v-select
          v-model="mainService"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

          :reduce="(val) => val.value"
          :options="optionMainService"
        />

      </b-col>
      <b-col
        cols="12"
        md="3"
        class="mb-md-0 mb-2"
      >
        <label>الخدمات الفرعية</label>
        <v-select
          v-model="serviceFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="serviceOptions"
          class="w-100"
          :reduce="(val) => val.value"
        />
      </b-col>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="400"
        :options="chartOptions"
        :series="chartOptions.series"
      />

      <!-- chart info -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    vSelect,
  },

  data() {
    return {
      data: [],

      chartOptions: {
        series: [
          {
            data: [],
          }],

        dataLabels: {
          enabled: false,
        },
        noData: {
          text: 'No Data...',
        },

        xaxis: {
          categories: ['قيد الانتظار', 'مفتوحة', 'مغلقة'],

        },

      },

      serviceFilter: '',
      mainService: '',
      serviceOptions: [],
      optionMainService: [],
    }
  },

  watch: {
    mainService() {
      this.serviceOptions = []
      const url = `/api/v1/main_services/${this.mainService}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.serviceOptions.push({ label: el.name, value: el.id })
        })
      })
    },
    serviceFilter() {
      const url = `/api/v1/stats/proposal_services/${this.serviceFilter}/statuses`
      this.$http.get(url).then(res => {
        window.dispatchEvent(new Event('resize'))
        // console.log(res)
        this.data = res.data[0].stats.values
        this.chartOptions.series[0].data = res.data[0].stats.values

        //       res.data[0].stats.forEach(el=>{

        //     if(el.name !=null){
        //    this.chartOptions.series.push(el.value)
        //  this.chartOptions.labels.push(el.name);}

        //       })
      })
    },

  },
  created() {
    this.getServiceType()
  },
  methods: {
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(res.data.data.main_services)
        proposal_services.forEach(el => {
          this.optionMainService.push({ label: el.name, value: el.id })
        })
      })
    },
  },
}
</script>

<style scoped>
.col-sm-6.col-xl-3 {
  padding: 13px;
}
</style>
